.App {
  text-align: center;
}

.AppContent {
  margin: auto;
  max-width: 30rem;
}

* {
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  background-color: #dfdfdf;
}

h1, h2 {
  color: #333333;
}

button {
  font: inherit;
  padding: 0.5rem 0.5rem;
  min-width: 6rem;
  cursor: pointer;
  border-radius: 4px;
  background-color: #800040;
  color: white;
  border: 1px solid #800040;
  margin: 0 0.5rem;
}
