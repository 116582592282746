.navheader {
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #77002e;
  padding: 0 10%;
}

.navlogo {
  font-size: 2rem;
  color: white;
  font-weight: bold;
}

.navheader ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: baseline;
}

.navheader li {
  margin-left: 3rem;
}

.navheader a {
  text-decoration: none;
  font-size: 1.5rem;
  color: #fcb8d2;
}

.navheader a:hover,
.navheader a:active,
.navheader a.active {
  color: white;
}

.badge {
  background-color: #cc2062;
  color: white;
  border-radius: 12px;
  padding: 0 1rem;
  margin-left: 0.5rem;
}
