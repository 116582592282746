.GameCard {
    width: 30rem;
    margin: 1rem 0;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}
  
  .GameImage {
    width: 100%;
    height: 8rem;
    overflow: hidden;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }

  .GameContent {
    padding: 1rem;
  }