.form {
    padding: 1rem;
    margin: 1rem 0;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    text-align: left;
  }
  
  .control {
    margin-bottom: 0.5rem;
  }
  
  .control label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .control label span {
    padding-left: 0.5rem;
    font-size: 0.75rem;
    font-style: italic;
    color: #999;
    font-weight: normal;
    margin-bottom: 0.5rem;
  }
  
  .control input,
  .control select,
  .control textarea {
    display: block;
    font: inherit;
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 0.25rem;
    width: 100%;
  }
  
  .actions {
    margin-top: 1rem;
    text-align: right;
  }
  
  .actions button {
    font: inherit;
    cursor: pointer;
    background-color: #77002e;
    color: white;
    padding: 0.5rem 1.5rem;
    border: 1px solid #77002e;
    border-radius: 4px;
    font-weight: bold;
  }
  
  .actions button:hover,
  .actions button:active {
    background-color: #a50e48;
    border-color: #a50e48;
  }


  .createErrorMsg {
    color: #800040;
    display: block;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
